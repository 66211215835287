import { FC } from 'react';
import { Config } from 'src/Config';

const snippetLower = `
    var _apply = _apply || [];
    var _applybase =  'https://stg.paradox.ai';
    _apply.push(['setKey', 'hvzfokosyvwfpthczchk']);
    _apply.push(['start']);
    (function() {
        var apply = document.createElement('script');
        apply.type = 'text/javascript';
        apply.async = true;
        apply.src = 'https://d25zu39ynyitwy.cloudfront.net/static/site/js/widget-client.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(apply, s);
    })();
`;

const snippetPrd = `
    var _apply = _apply || [];
    var _applybase =  'https://olivia.paradox.ai';
    _apply.push(['setKey', 'jbpzozpaabtllbtpjdpn']);
    _apply.push(['start']);
    (function() {
        var apply = document.createElement('script');
        apply.type = 'text/javascript';
        apply.async = true;
        apply.src = 'https://dokumfe7mps0i.cloudfront.net/static/site/js/widget-client.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(apply, s);
    })();
`;

export const Paradox: FC = () => {
	const snippet = Config.Env.IsPrd ? snippetPrd : snippetLower;
	return <script id='paradox' dangerouslySetInnerHTML={{ __html: snippet }} />;
};
