var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/useConstructor.ts
import { useRef } from "react";
var useConstructor = (callback) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current)
    return;
  callback();
  hasBeenCalled.current = true;
};

// src/useDebounce.ts
import { useEffect, useState } from "react";
var USE_DEBOUNCE_DEFAULT_TIMER = 500;
var useDebounce = (value, delay = USE_DEBOUNCE_DEFAULT_TIMER) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

// src/useEventCallback.ts
import { useCallback, useRef as useRef2 } from "react";

// src/useIsoEffect.ts
import { useEffect as useEffect2, useLayoutEffect } from "react";
var useIsoEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect2;

// src/useEventCallback.ts
var useEventCallback = (fn) => {
  const ref = useRef2(() => {
    throw new Error("Cannot call an event handler while rendering.");
  });
  useIsoEffect(() => {
    ref.current = fn;
  }, [fn]);
  return useCallback((...args) => ref.current(...args), [ref]);
};

// src/useEventListener.ts
import { useEffect as useEffect3, useRef as useRef3 } from "react";
function useEventListener(eventName, handler, element, options) {
  const savedHandler = useRef3(handler);
  useIsoEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect3(() => {
    var _a;
    const targetElement = (_a = element == null ? void 0 : element.current) != null ? _a : window;
    if (!(targetElement && targetElement.addEventListener))
      return;
    const listener = (event) => savedHandler.current(event);
    targetElement.addEventListener(eventName, listener, options);
    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, element, options]);
}

// src/useFocusWIthin.ts
import { useEffect as useEffect4, useState as useState2 } from "react";
var useFocusWithin = (ref) => {
  const [isFocusWithin, setIsFocusWithin] = useState2(false);
  useEffect4(() => {
    const onClick = () => {
      if (ref.current) {
        setIsFocusWithin(ref.current.contains(document.activeElement));
      }
    };
    const onTabPress = (e) => {
      if (ref.current && e.key === "Tab") {
        setIsFocusWithin(ref.current.contains(document.activeElement));
      }
    };
    if (ref.current) {
      document.addEventListener("click", onClick);
      document.addEventListener("keyup", onTabPress);
    }
    return () => {
      document.removeEventListener("click", onClick);
      document.removeEventListener("keyup", onTabPress);
    };
  }, [ref]);
  return isFocusWithin;
};

// src/useInput.ts
import { useState as useState3, useEffect as useEffect5 } from "react";
function useInput(first = void 0, second = void 0) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const [value, _setValue] = useState3(first != null ? first : void 0);
  const [loading, _setLoading] = useState3((_a = second == null ? void 0 : second.loading) != null ? _a : false);
  const [disabled, _setDisabled] = useState3((_b = second == null ? void 0 : second.disabled) != null ? _b : false);
  const [error, _setError] = useState3((_c = second == null ? void 0 : second.error) != null ? _c : "");
  const [label, _setLabel] = useState3((_d = second == null ? void 0 : second.label) != null ? _d : "");
  const [placeholder, _setPlaceholder] = useState3((_e = second == null ? void 0 : second.placeholder) != null ? _e : void 0);
  const [maxlength, _setMaxlength] = useState3((_f = second == null ? void 0 : second.maxlength) != null ? _f : void 0);
  const [charCounter, _setCharCounter] = useState3((_g = second == null ? void 0 : second.charCounter) != null ? _g : false);
  const [required, _setRequired] = useState3((_h = second == null ? void 0 : second.required) != null ? _h : false);
  const [onChange, _setOnChange] = useState3(
    (second == null ? void 0 : second.onChange) ? () => second.onChange : void 0
  );
  const [onFocus, _setOnFocus] = useState3((second == null ? void 0 : second.onFocus) ? () => second.onFocus : void 0);
  const [onBlur, _setOnBlur] = useState3((second == null ? void 0 : second.onBlur) ? () => second.onBlur : void 0);
  const [icon, _setIcon] = useState3();
  const [mask, _setMask] = useState3((_i = second == null ? void 0 : second.mask) != null ? _i : "");
  const [id, _setId] = useState3((_j = second == null ? void 0 : second.id) != null ? _j : "");
  useEffect5(() => {
    setOnChange((second == null ? void 0 : second.onChange) ? () => second.onChange : void 0);
    setOnBlur((second == null ? void 0 : second.onBlur) ? () => second.onBlur : void 0);
  }, [value]);
  const setValue = (value2) => {
    _setValue(value2);
    return setters;
  };
  const setLoading = (loading2) => {
    _setLoading(loading2);
    return setters;
  };
  const setDisabled = (disabled2) => {
    _setDisabled(disabled2);
    return setters;
  };
  const setError = (error2) => {
    _setError(error2);
    return setters;
  };
  const setLabel = (label2) => {
    _setLabel(label2);
    return setters;
  };
  const setPlaceholder = (placeholder2) => {
    _setPlaceholder(placeholder2);
    return setters;
  };
  const setMaxlength = (maxlength2) => {
    _setMaxlength(maxlength2);
    return setters;
  };
  const setCharCounter = (charCounter2) => {
    _setCharCounter(charCounter2);
    return setters;
  };
  const setRequired = (required2) => {
    _setRequired(required2);
    return setters;
  };
  const setOnChange = (onChange2) => {
    _setOnChange(onChange2);
    return setters;
  };
  const setOnFocus = (onFocus2) => {
    _setOnFocus(onFocus2);
    return setters;
  };
  const setOnBlur = (onBlur2) => {
    _setOnBlur(onBlur2);
    return setters;
  };
  const setIcon = (icon2) => {
    _setIcon(icon2);
    return setters;
  };
  const setMask = (mask2) => {
    _setMask(mask2);
    return setters;
  };
  const setId = (id2) => {
    _setId(id2);
    return setters;
  };
  const setters = {
    setValue,
    setLoading,
    setDisabled,
    setError,
    setLabel,
    setPlaceholder,
    setMaxlength,
    setCharCounter,
    setRequired,
    setOnChange,
    setOnFocus,
    setOnBlur,
    setIcon,
    setMask,
    setId
  };
  return __spreadValues({
    value,
    loading,
    disabled,
    error,
    label,
    placeholder,
    maxlength,
    charCounter,
    required,
    onChange,
    onFocus,
    onBlur,
    icon,
    mask,
    id
  }, setters);
}

// src/useInterval.ts
import { useEffect as useEffect6, useRef as useRef4 } from "react";
var useInterval = (callback, delay) => {
  const intervalRef = useRef4(null);
  const callbackRef = useRef4(callback);
  useEffect6(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffect6(() => {
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay);
      return () => {
        window.clearInterval(intervalRef.current || 0);
      };
    }
  }, [delay]);
  return intervalRef;
};

// src/useLocalStorage.ts
import { useCallback as useCallback2, useEffect as useEffect7, useState as useState4 } from "react";
var useLocalStorage = (key, initialValue) => {
  const readValue = useCallback2(() => {
    if (typeof window === "undefined")
      return initialValue;
    try {
      const item = window.localStorage.getItem(key);
      return item ? parseJSON(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key \u201C${key}\u201D:`, error);
      return initialValue;
    }
  }, [initialValue, key]);
  const [storedValue, setStoredValue] = useState4(readValue);
  const setValue = useEventCallback((value) => {
    if (typeof window === "undefined")
      console.warn(`Tried setting localStorage key \u201C${key}\u201D even though environment is not a client`);
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      window.localStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
      window.dispatchEvent(new Event("local-storage"));
    } catch (error) {
      console.warn(`Error setting localStorage key \u201C${key}\u201D:`, error);
    }
  });
  useEffect7(() => {
    setStoredValue(readValue());
  }, []);
  const handleStorageChange = useCallback2(
    (event) => {
      if ((event == null ? void 0 : event.key) && event.key !== key)
        return;
      setStoredValue(readValue());
    },
    [key, readValue]
  );
  useEventListener("storage", handleStorageChange);
  useEventListener("local-storage", handleStorageChange);
  return [storedValue, setValue];
};
var parseJSON = (value) => {
  try {
    return value === "undefined" ? void 0 : JSON.parse(value != null ? value : "");
  } catch (e) {
    console.log("parsing error on", { value });
    return void 0;
  }
};

// src/useOnOutsideClick.ts
import { useEffect as useEffect8 } from "react";
var useOnOutsideClick = (refs, handler) => {
  useEffect8(() => {
    const listener = (ev) => {
      const refArray = Array.isArray(refs) ? refs : [refs];
      let isClickInside = false;
      refArray.forEach((ref) => {
        if (ref.current && ref.current.contains(ev.target)) {
          isClickInside = true;
        }
      });
      if (!isClickInside) {
        handler(ev);
      }
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [refs, handler]);
};

// src/usePrevious.ts
import { useRef as useRef5, useEffect as useEffect9 } from "react";
var usePrevious = (value) => {
  const ref = useRef5();
  useEffect9(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// src/useRest.ts
import { useEffect as useEffect10, useState as useState5 } from "react";
var useRest = (promise, cleanUp) => {
  const [promiseStatus, setPromiseStatus] = useState5({
    loading: false,
    error: false,
    data: null
  });
  useEffect10(() => {
    setPromiseStatus((status) => __spreadProps(__spreadValues({}, status), {
      error: false,
      loading: true
    }));
    const runPromise = () => __async(void 0, null, function* () {
      const data = yield promise();
      setPromiseStatus((status) => __spreadProps(__spreadValues({}, status), {
        data: data.body,
        loading: false,
        error: data.error
      }));
    });
    runPromise();
    return cleanUp == null ? void 0 : cleanUp();
  }, [promise, cleanUp]);
  return __spreadValues({}, promiseStatus);
};

// src/useScrollThreshold.ts
import { useState as useState6, useEffect as useEffect11 } from "react";
var useScrollThreshold = (threshold) => {
  const [hasScrolled, setHasScrolled] = useState6(false);
  const handleScroll = () => {
    const scrolled = window.pageYOffset !== void 0 ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    const _hasScrolled = scrolled > threshold;
    if (hasScrolled !== _hasScrolled)
      setHasScrolled(_hasScrolled);
  };
  useEffect11(() => {
    handleScroll();
  }, []);
  useEffect11(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);
  return hasScrolled;
};

// src/useSessionStorage.ts
import { useCallback as useCallback3, useEffect as useEffect12, useState as useState7 } from "react";
var useSessionStorage = (key, initialValue) => {
  const readValue = useCallback3(() => {
    if (typeof window === "undefined")
      return initialValue;
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? parseJSON2(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading sessionStorage key \u201C${key}\u201D:`, error);
      return initialValue;
    }
  }, [initialValue, key]);
  const [storedValue, setStoredValue] = useState7(readValue);
  const setValue = useEventCallback((value) => {
    if (typeof window == "undefined")
      console.warn(`Tried setting sessionStorage key \u201C${key}\u201D even though environment is not a client`);
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      window.sessionStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
      window.dispatchEvent(new Event("session-storage"));
    } catch (error) {
      console.warn(`Error setting sessionStorage key \u201C${key}\u201D:`, error);
    }
  });
  useEffect12(() => {
    setStoredValue(readValue());
  }, []);
  const handleStorageChange = useCallback3(
    (event) => {
      if ((event == null ? void 0 : event.key) && event.key !== key)
        return;
      setStoredValue(readValue());
    },
    [key, readValue]
  );
  useEventListener("storage", handleStorageChange);
  useEventListener("session-storage", handleStorageChange);
  return [storedValue, setValue];
};
var parseJSON2 = (value) => {
  try {
    return value === "undefined" ? void 0 : JSON.parse(value != null ? value : "");
  } catch (e) {
    console.log("parsing error on", { value });
    return void 0;
  }
};

// src/useTimeout.ts
import { useEffect as useEffect13, useRef as useRef6 } from "react";
var useTimeout = (callback, delay) => {
  const timeoutRef = useRef6(null);
  const callbackRef = useRef6(callback);
  useEffect13(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffect13(() => {
    if (typeof delay === "number") {
      timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
      return () => {
        window.clearTimeout(timeoutRef.current || 0);
      };
    }
  }, [delay]);
  return timeoutRef;
};

// src/useForceRender.ts
import { useState as useState8 } from "react";
var useForceRender = () => {
  const [, setValue] = useState8(0);
  return () => setValue((v) => v + 1);
};

// src/useIsMobile.ts
import { useState as useState9, useEffect as useEffect14 } from "react";
var useIsMobile = (threshold = 1280) => {
  const [isMobile, setIsMobile] = useState9(true);
  const handleResize = () => {
    const _isMobile = window.innerWidth < threshold;
    if (_isMobile !== isMobile) {
      setIsMobile(_isMobile);
    }
  };
  useEffect14(() => {
    handleResize();
  }, []);
  useEffect14(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  return isMobile;
};

// src/useSpyScrollArea.ts
import { useEffect as useEffect15, useState as useState10 } from "react";
var useSpyScrollArea = (elements, area) => {
  const [activeElement, setActiveElement] = useState10("");
  const spyElementsOnScrollArea = () => {
    elements.current.forEach((element) => {
      if (!element.current)
        return;
      const position = element.current.getBoundingClientRect().top;
      if (position >= area.start && position <= area.end) {
        setActiveElement(element.current.id);
      }
    });
  };
  useEffect15(() => {
    document.addEventListener("scroll", spyElementsOnScrollArea);
    return () => document.removeEventListener("scroll", spyElementsOnScrollArea);
  }, [activeElement]);
  return [activeElement, setActiveElement];
};

// src/useIsFirstRender.ts
import { useRef as useRef7 } from "react";
var useIsFirstRender = () => {
  const isFirst = useRef7(true);
  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return isFirst.current;
};

// src/useUpdateEffect.ts
import { useEffect as useEffect16 } from "react";
var useUpdateEffect = (effect, deps) => {
  const isFirst = useIsFirstRender();
  useEffect16(() => {
    if (!isFirst) {
      return effect();
    }
  }, deps);
};

// src/useOS.ts
import { useState as useState11, useEffect as useEffect17 } from "react";
var useOS = () => {
  const [userAgent, setUserAgent] = useState11();
  const [platform, setPlatform] = useState11();
  const iosPlatforms = ["iphone", "ipad", "ipod"];
  const macPlataforms = ["macintosh", "macintel", "macppc", "mac68k", "mac"];
  const windowsPlataforms = ["win32", "win64", "windows", "wince"];
  useEffect17(() => {
    var _a, _b;
    setUserAgent((_a = window.navigator) == null ? void 0 : _a.userAgent);
    setPlatform((_b = window.navigator) == null ? void 0 : _b.platform);
  }, []);
  const osData = {
    isAndroid: false,
    isiOS: false,
    isWindows: false,
    isMac: false,
    isLinux: false,
    isMobile: false,
    isApple: false
  };
  if (userAgent && /android/i.test(userAgent.toLowerCase()))
    osData.isAndroid = true;
  if (platform && iosPlatforms.indexOf(platform.toLowerCase()) !== -1 || userAgent && iosPlatforms.indexOf(userAgent.toLowerCase()) !== -1)
    osData.isiOS = true;
  if (userAgent && windowsPlataforms.indexOf(userAgent.toLowerCase()) !== -1 || platform && windowsPlataforms.indexOf(platform.toLowerCase()) !== -1)
    osData.isWindows = true;
  if (platform && macPlataforms.indexOf(platform.toLowerCase()) !== -1 || userAgent && macPlataforms.indexOf(userAgent.toLowerCase()) !== -1)
    osData.isMac = true;
  if (userAgent && /linux/i.test(userAgent.toLowerCase()))
    osData.isLinux = true;
  if (osData.isAndroid || osData.isiOS)
    osData.isMobile = true;
  if (osData.isiOS || osData.isMac)
    osData.isApple = true;
  return osData;
};

// src/useTrapFocus.ts
import { useEffect as useEffect18 } from "react";
var useTrapFocus = (containerRef) => {
  const handleFocus = (event) => {
    var _a;
    const focusableElements = (_a = containerRef.current) == null ? void 0 : _a.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    );
    if (!focusableElements)
      return;
    const firstElement = focusableElements == null ? void 0 : focusableElements[0];
    const lastElement = focusableElements == null ? void 0 : focusableElements[(focusableElements == null ? void 0 : focusableElements.length) - 1];
    let isTab = event.key === "Tab";
    if (!isTab)
      return;
    if (event.shiftKey) {
      if (document.activeElement === firstElement) {
        lastElement.focus();
        event.preventDefault();
      }
    } else {
      if (document.activeElement === lastElement) {
        firstElement.focus();
        event.preventDefault();
      }
    }
  };
  useEffect18(() => {
    var _a;
    (_a = containerRef.current) == null ? void 0 : _a.addEventListener("keydown", handleFocus);
    return () => {
      var _a2;
      (_a2 = containerRef.current) == null ? void 0 : _a2.removeEventListener("keydown", handleFocus);
    };
  }, []);
};

// src/useKeyboardStatus.ts
import { useEffect as useEffect19, useState as useState12 } from "react";
var MIN_KEYBOARD_SIZE = 300;
var useKeyboardStatus = () => {
  const [keyboardStatus, setKeyboardStatus] = useState12({
    isOpen: false,
    height: 0
  });
  useEffect19(() => {
    function handleResize() {
      var _a;
      const bodyHeight = window.screen.height;
      const windowHeight = ((_a = window == null ? void 0 : window.visualViewport) == null ? void 0 : _a.height) || window.innerHeight;
      const keyboardOpen = bodyHeight - MIN_KEYBOARD_SIZE > windowHeight;
      const keyboardHeight = bodyHeight - windowHeight;
      setKeyboardStatus({ isOpen: keyboardOpen, height: keyboardHeight });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return keyboardStatus;
};

// src/useIsOnScreen.ts
import { useEffect as useEffect20, useState as useState13 } from "react";
var useIsOnScreen = (ref, options = {}) => {
  const [isIntersecting, setIsIntersecting] = useState13(false);
  let { rootMargin, threshold } = options;
  if (!rootMargin)
    rootMargin = "0px";
  if (!threshold)
    threshold = 1;
  useEffect20(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin, threshold }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return isIntersecting;
};

// src/useScrollDirection.ts
import { useState as useState14, useEffect as useEffect21 } from "react";
var useScrollDirection = () => {
  const [scrollDir, setScrollDir] = useState14("");
  useEffect21(() => {
    let prevScrollY = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (prevScrollY < currentScrollY) {
        setScrollDir("down");
      } else if (prevScrollY > currentScrollY) {
        setScrollDir("up");
      }
      prevScrollY = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return scrollDir;
};

// src/useBrowser.ts
var Browser = /* @__PURE__ */ ((Browser2) => {
  Browser2["Chrome"] = "Chrome";
  Browser2["Safari"] = "Safari";
  Browser2["Firefox"] = "Firefox";
  Browser2["Edge"] = "Edg";
  Browser2["Opera"] = "OPR";
  Browser2["Default"] = "Default";
  return Browser2;
})(Browser || {});
var useBrowser = () => {
  const userAgent = window.navigator.userAgent;
  const chrome = userAgent.indexOf("Chrome" /* Chrome */) > -1;
  const safari = userAgent.indexOf("Safari" /* Safari */) > -1;
  const firefox = userAgent.indexOf("Firefox" /* Firefox */) > -1;
  const edge = userAgent.indexOf("Edg" /* Edge */) > -1;
  const opera = userAgent.indexOf("OPR" /* Opera */) > -1;
  if (edge)
    return "Edg" /* Edge */;
  if (opera)
    return "OPR" /* Opera */;
  if (firefox)
    return "Firefox" /* Firefox */;
  if (safari) {
    if (chrome)
      return "Chrome" /* Chrome */;
    return "Safari" /* Safari */;
  }
  return "Default" /* Default */;
};

// src/useCookies.ts
import { GetCookie } from "@maverick/utils";
import { useEffect as useEffect22, useState as useState15 } from "react";
var useCookies = (cookieName) => {
  const [cookies, setCookies] = useState15();
  useEffect22(() => {
    setCookies(GetCookie(cookieName));
    function onChange() {
      const newCookies = GetCookie(cookieName);
      if (newCookies !== cookies) {
        setCookies(newCookies);
      }
    }
    if ("cookieStore" in window) {
      window.cookieStore.addEventListener("change", onChange);
    } else {
      setInterval(onChange, 300);
    }
    return () => {
      if ("cookieStore" in window) {
        window.cookieStore.removeEventListener("change", onChange);
      }
    };
  });
  return [cookies];
};

// src/UseGrabFocus.ts
import { useEffect as useEffect23 } from "react";
var useGrabFocus = (condition, ref) => {
  useEffect23(() => {
    const grabFocus = (ev) => {
      var _a;
      if (ev.key !== "Tab" || ref.current && ref.current.contains(ev.target)) {
        return;
      }
      ev.preventDefault();
      const firstFocusableElement = (_a = ref.current) == null ? void 0 : _a.querySelector(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
      window.removeEventListener("keydown", grabFocus);
    };
    if (condition) {
      window.addEventListener("keydown", grabFocus);
    }
    return () => {
      window.removeEventListener("keydown", grabFocus);
    };
  }, [condition]);
};
export {
  Browser,
  USE_DEBOUNCE_DEFAULT_TIMER,
  useBrowser,
  useConstructor,
  useCookies,
  useDebounce,
  useEventCallback,
  useEventListener,
  useFocusWithin,
  useForceRender,
  useGrabFocus,
  useInput,
  useInterval,
  useIsFirstRender,
  useIsMobile,
  useIsOnScreen,
  useIsoEffect,
  useKeyboardStatus,
  useLocalStorage,
  useOS,
  useOnOutsideClick,
  usePrevious,
  useRest,
  useScrollDirection,
  useScrollThreshold,
  useSessionStorage,
  useSpyScrollArea,
  useTimeout,
  useTrapFocus,
  useUpdateEffect
};
