import type { AppProps } from 'next/app';
import Router from 'next/router';
import { useRouter } from 'next/router';
import { I18nProvider } from 'next-localization';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Paradox } from 'components/cms/Paradox/Paradox';
import { FunctionalCookies, TooltipProvider } from '@maverick/ui';
import { Provider } from 'react-redux';
import Head from 'next/head';
import '../globals.css';
import 'radar-sdk-js/dist/radar.css';
import { StoreWrapper } from '@maverick/store';
import { Config } from 'src/Config';

NProgress.configure({ showSpinner: false, trickleSpeed: 100 });

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function App({ Component, pageProps }: AppProps): JSX.Element {
	const { store } = StoreWrapper.useWrappedStore(pageProps);
	const router = useRouter();
	const lngDict = pageProps;
	const rest = pageProps;

	return (
		<>
			<Head>
				<link rel='canonical' href={`${Config.Urls.Cms}${router.asPath}`} />
				<title>{`Outback Steakhouse`}</title>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
			</Head>

			<FunctionalCookies />
			<Provider store={store}>
				<TooltipProvider>
					<I18nProvider lngDict={lngDict} locale={router.locale ?? ''}>
						<Component {...rest} />
					</I18nProvider>
					<Paradox />
				</TooltipProvider>
			</Provider>
		</>
	);
}

export default App;
